import $ from "jquery";

import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";

import moment from "moment";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import indexOf from "lodash/indexOf";
import findIndex from "lodash/findIndex";
import concat from "lodash/concat";
import flatten from "lodash/flatten";

import config from "../config";
import i18nTools from "../helpers/i18nTools";
import { isCompanyAdminOrUser } from "../helpers/user";

import lodashMap from "lodash/map";
import FlightsArcIcon from "../resources/img/mapicons/Ellipse(3).png";
import OK from "../resources/img/mapicons/ok.png";
import Alarm from "../resources/img/mapicons/alarm.png";
import Warning from "../resources/img/mapicons/warning.png";
import Traveler from "../resources/img/mapicons/Traveler.svg";
import continentsJSON from "../config/continents.geo.json";

import { sprintf } from "../i18n/utils";

const { getTimezoneTime, l } = i18nTools;

mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken = config.map.mapAccessToken;
let id;

export const getBBoxForContinent = (continent) => {
  let continentFeature = continentsJSON.features.find( (continentFeature) => continentFeature.properties.name === continent);
  if (continentFeature) {
    let bbox = turf.bbox(continentFeature.geometry) ;
    return [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]]
    ]
  }
  return [];
}

const updateWithWeather = (airportCode) => {

  let url = `https://api.weatherapi.com/v1/forecast.json?key=${config.weatherApi.apiKey}&q=${airportCode}&days=4&aqi=no&alerts=no&hour=-1`;
  $.get( url, function( data ) {
    let divId = airportCode + '-weather';
    let weatherList = [];
    data.forecast.forecastday.forEach( (weather, i) => {
      let d = moment(weather.date, "YYYY-MM-DD");
      let dayOfWeek = d.format('dddd');
      let weatherDay = {
        'day' : i == 0 ? 'Current' : dayOfWeek.substr(0,3).toUpperCase(),
        'maxtemp_f': Math.round(weather.day.maxtemp_f),
        'mintemp_f': Math.round(weather.day.mintemp_f),
        'img' : weather.day.condition.icon
      }
      weatherList.push(weatherDay)
    });

    let weatherHTML = weatherList.map( (weather) =>{
      let html = `
        <div style="padding-right: 0.5rem;text-align-last: center;">
          <div style="font-size: 9px;font-weight: bold;padding-bottom: 5px">${weather.day}</div>
          <img src="${weather.img}" style="width: 32px;height: 32px; margin-top: -5px;margin-bottom: -9px;"/>
          <div style="font-weight: bold;font-size: 9px;padding-top: 6px;text-align-last:center;">
          <span>${Math.round(weather.mintemp_f)}</span>
          <span style="color: gray">${Math.round(weather.maxtemp_f)}</span>
          </div>
        </div>
      `;
      return html
    })
    $('#' + divId).html(weatherHTML.join(''));
  });

}
export const createMap = (
  containerID,
  centerLongitude,
  centerLatitude,
  initialZoom,
  defaultContinent
) => {
  let bbox = [];
  if (!centerLongitude) {
    // This happens after the first load.
    bbox = getBBoxForContinent(defaultContinent);
  }

  if (!centerLongitude) {
    centerLongitude = config.map.defaultLon;
  }

  if (!centerLatitude) {
    centerLatitude = config.map.defaultLat;
  }

  if (!initialZoom) {
    initialZoom = config.map.minZoom;
  }

  const newMap = new mapboxgl.Map({
    container: containerID,
    trackResize: true,
    center: [centerLongitude, centerLatitude],
    style: config.map.mapView,
    zoom: initialZoom,
    minZoom: config.map.minZoom,
    maxZoom: config.map.maxZoom,
    attributionControl: true,
    logoPosition: "top-right"
  });
  // Set the map to fit the bounds of the defaultContinent if bbox is available
  if (bbox.length) {
    newMap.fitBounds(bbox);
  }

  const nav = new mapboxgl.NavigationControl();
  newMap.addControl(nav, "bottom-right");

  return newMap;
};

export const drawAirportStatusesHelper = (
  airportMarkers,
  airportStatuses,
  map,
  timeFilter,
  pageType,
  popup
) => {
airportStatuses.forEach(airport => {
    if (
      !parseFloat(airport.geoLocation.latitude) ||
      !parseFloat(airport.geoLocation.longitude)
    ) {
      return;
    }

    const airportCode = airport.code
      ? '<span class="airport-code">' + airport.code + "</span>"
      : "";

    // Tarams: New marker Code
    const ids = uniq(
      lodashMap(airport.travelersIn || airport.travelers, "_id.$oid")
    );

    //###################################################################
    //###################################################################

    const markerElement = document.createElement("div");
    markerElement.className =
      "airport-marker airport-style pointer "  +
      (airport.status && airport.status.color ? airport.status.color : "none");
    markerElement.html = '<div class="bullet"></div>' + airportCode;
    markerElement.style.width = "20px";
    markerElement.style.height = "20px";
    markerElement.ids = ids;
    markerElement.code = airport.code;
    const city =
      airport.language && airport.language[0].locations
        ? airport.language[0].locations[0].city
        : "";

    // OLD MARKER CODE, delete me
    // const marker = L.marker([airport.geoLocation.latitude, airport.geoLocation.longitude], {
    //   icon: markerIcon
    // }).addTo(map);

    // Tarams: new marker adding to map
    let travelersLength = uniq(ids).length;
    travelersLength = sprintf(
      i18nTools.ngettext("%d traveler", "%d travelers", travelersLength),
      travelersLength
    );
    const hoursLables = {
      48: "48 hours",
      36: "36 hours",
      24: "24 hours",
      12: "12 hours",
      6: "6 hours",
      3: "3 hours",
      1.5: "90 min",
      1: "60 min",
      0.5: "30 min"
    };
    let travelersNumberHtml = "";
    if (pageType === "active-travelers") {
      travelersNumberHtml = `
        <div style="display: flex;padding-top: 10px">
        <div><img style="height:20px;width:20px;margin-right:5px;" src=${Traveler} /></div><div style="font-size: 16px">${travelersLength} ${i18nTools.l(
        "within"
      )} ${hoursLables[timeFilter]}</div></div>
      `;
    }
  if (pageType === "active-traveler") {
    travelersNumberHtml = `
        
      `;
  }

    let popupHtml = `
      <div class='' style="padding-top: 7px"> <div class='airport_status_circle ${
              airport.status.color
            }'>
              
            </div>
            <div style="display: flex;padding-bottom: 0.8rem;justify-content: space-between;">
            <div style=' color: #101427; font-size: 18px;font-weight: bold;align-self: center;'>${
              airport.code
            } - ${city}</div>
            <div id='${airport.code}-weather' style='display: flex; padding-left: 10px'></div>
            </div>
            </div><div class='area'><span class='airport-status ' style="font-size: 16px;width: 330px;display: flex"><i class="fas fa-circle ${airport.status.color}" style="padding-right: 8px;padding-top: 4px;background-color: white"></i><span>${
              airport.status
                ? airport.status.message || airport.status.reason
                : ""
            }</span></span> ${travelersNumberHtml}</div>`

    const marker = new mapboxgl.Marker(markerElement)
      .setLngLat([airport.geoLocation.longitude, airport.geoLocation.latitude])
      .addTo(map);

    airportMarkers.push(marker);
    const markerDiv = marker.getElement();

    markerDiv.addEventListener('mouseenter', () => {
      popup.remove();
      popup.
        setLngLat([airport.geoLocation.longitude, airport.geoLocation.latitude]).
        setHTML( popupHtml ).
        addTo(map);
      updateWithWeather(airport.code);
    });

  });

  return airportMarkers;
};

export const getClusterInformationMarkersStatus = markers => {
  return groupBy(markers, "options.icon.options.status");
};

// ##################
const SOURCE_EARTHQUAKE = "travellerslist";

const SPIDERIFY_AFTER_ZOOM = config.map.maxZoom; // Spiderify after zoom N, zoom otherwise
const SPIDER_TYPE = "layer"; // marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
const MAX_LEAVES_TO_SPIDERIFY = 255; // Max leave to display when spiderify to prevent filling the map with leaves
const CIRCLE_TO_SPIRAL_SWITCHOVER =
  SPIDER_TYPE.toLowerCase() === "marker" ? 10 : 15; // When below number, will display leave as a circle. Over, as a spiral

const CIRCLE_OPTIONS = {
  distanceBetweenPoints: 50
};
let spiderLeavesCollection = [];
const SPIRAL_OPTIONS = {
  rotationsModifier: 1250, // Higher modifier = closer spiral lines
  distanceBetweenPoints: SPIDER_TYPE.toLowerCase() === "marker" ? 42 : 32, // Distance between points in spiral
  radiusModifier: 50000, // Spiral radius
  lengthModifier: 1000 // Spiral length modifier
};

const SPIDER_LEGS = true;
const SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()
  .toString(36)
  .substr(2, 9)}`;
const SPIDER_LEGS_PAINT_OPTION = {
  "line-width": 1,
  "line-color": "rgba(128, 128, 128, 0.5)"
};

const SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${Math.random()
  .toString(36)
  .substr(2, 9)}`;

var colorExpressionPoint = [
  "case",
  ["==", ["get", "currentJourneyStatus"], "alarm"],
  "#F24F4F",
  ["==", ["get", "currentJourneyStatus"], "warning"],
  "#FEB902",
  ["==", ["get", "currentJourneyStatus"], "ok"],
  "#4CC079",
  "#002D72"
];
const SPIDER_LEAVES_PAINT_OPTION = {
  "circle-color": colorExpressionPoint,
  "circle-radius": 6
};

let clusterMarkers = [];
let spiderifiedCluster = {};
function clearSpierifiedMarkers() {
  if (clusterMarkers.length > 0) {
    for (let i = 0; i < clusterMarkers.length; i++) {
      clusterMarkers[i].remove();
    }
  }
  clusterMarkers = [];
}

function removeSourceAndLayer(map, id) {
  if (map.getLayer(id) != null) map.removeLayer(id);
  if (map.getSource(id) != null) map.removeSource(id);
}

function clearSpiderifiedCluster(map) {
  spiderifiedCluster = {};
  spiderLeavesCollection = [];
  removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME);
  removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME);
  clearSpierifiedMarkers();
}

function generateEquidistantPointsInCircle({
  totalPoints = 1,
  options = CIRCLE_OPTIONS
}) {
  let points = [];
  let theta = (Math.PI * 2) / totalPoints;
  let angle = theta;
  for (let i = 0; i < totalPoints; i++) {
    angle = theta * i;
    points.push({
      x: options.distanceBetweenPoints * Math.cos(angle),
      y: options.distanceBetweenPoints * Math.sin(angle)
    });
  }
  return points;
}

function generateEquidistantPointsInSpiral({
  totalPoints = 10,
  options = SPIRAL_OPTIONS
}) {
  let points = [{ x: 0, y: 0 }];
  // Higher modifier = closer spiral lines
  const rotations = totalPoints * options.rotationsModifier;
  const distanceBetweenPoints = options.distanceBetweenPoints;
  const radius = totalPoints * options.radiusModifier;
  // Value of theta corresponding to end of last coil
  const thetaMax = rotations * 2 * Math.PI;
  // How far to step away from center for each side.
  const awayStep = radius / thetaMax;
  for (
    let theta = distanceBetweenPoints / awayStep;
    points.length <= totalPoints + options.lengthModifier;

  ) {
    points.push({
      x: Math.cos(theta) * (awayStep * theta),
      y: Math.sin(theta) * (awayStep * theta)
    });
    theta += distanceBetweenPoints / (awayStep * theta);
  }
  return points.slice(0, totalPoints);
}
function generateLeavesCoordinates({ nbOfLeaves }) {
  // Position cluster's leaves in circle if below threshold, spiral otherwise
  let points = [{ x: 0, y: 0 }];
  if (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER) {
    points = generateEquidistantPointsInCircle({
      totalPoints: nbOfLeaves
    });
  } else {
    points = generateEquidistantPointsInSpiral({
      totalPoints: nbOfLeaves
    });
  }
  return points;
}

function spiderifyCluster({ map, source, clusterToSpiderify, userRole, popup }) {
  let spiderlegsCollection = [];
  spiderLeavesCollection = [];

  map
    .getSource(source)
    .getClusterLeaves(
      clusterToSpiderify.id,
      MAX_LEAVES_TO_SPIDERIFY,
      0,
      (error, features) => {
        if (error) {
          console.warning("Cluster does not exists on this zoom");
          return;
        }

        let leavesCoordinates = generateLeavesCoordinates({
          nbOfLeaves: features.length
        });

        let clusterXY = map.project(clusterToSpiderify.coordinates);

        // Generate spiderlegs and leaves coordinates
        features.forEach((element, index) => {
          let spiderLeafLatLng = map.unproject([
            clusterXY.x + leavesCoordinates[index].x,
            clusterXY.y + leavesCoordinates[index].y
          ]);

          if (SPIDER_TYPE.toLowerCase() === "marker") {
            clusterMarkers.push(
              new mapboxgl.Marker().setLngLat(spiderLeafLatLng)
            );
          }
          if (SPIDER_TYPE.toLowerCase() === "layer") {
            spiderLeavesCollection.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
              },
              properties: element.properties
            });
          }

          if (SPIDER_LEGS) {
            spiderlegsCollection.push({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  clusterToSpiderify.coordinates,
                  [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                ]
              },
              properties: element.properties
            });
          }
        });

        // Draw spiderlegs and leaves coordinates
        if (SPIDER_LEGS) {
          if (!map.getLayer(SPIDER_LEGS_LAYER_NAME)) {
            map.addLayer({
              id: SPIDER_LEGS_LAYER_NAME,
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderlegsCollection
                }
              },
              paint: SPIDER_LEGS_PAINT_OPTION
            });
          }
        }

        if (SPIDER_TYPE.toLowerCase() === "marker") {
          clusterMarkers.forEach(marker => marker.addTo(map));
        }
        if (SPIDER_TYPE.toLowerCase() === "layer") {
          if (!map.getLayer(SPIDER_LEAVES_LAYER_NAME)) {
            map.addLayer({
              id: SPIDER_LEAVES_LAYER_NAME,
              type: "circle",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderLeavesCollection
                }
              },
              paint: SPIDER_LEAVES_PAINT_OPTION
            });
          }
        }
      }
    );


  map.on("mouseleave", SPIDER_LEAVES_LAYER_NAME, function() {
    map.getCanvas().style.cursor = ''
  });
  map.on("mouseenter", SPIDER_LEAVES_LAYER_NAME, function(e) {
    map.getCanvas().style.cursor = 'pointer'
    var coordinates = e.features[0].geometry.coordinates.slice();

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    let traveler = JSON.parse(e.features[0].properties.traveler);
    let popupHtml = getTravelerLocationPopupContent(traveler, userRole);
    popup
      .setLngLat(coordinates)
      .setHTML(popupHtml)
      .addTo(map);
  });
}

// ##################
var colorsarray = ["#E3A970", "#D59085", "#007680", "#EED300"];

function addHoverEffect(map) {
  var hoveredStateId = null;
  if (!map.getSource("stateshover")) {
    map.addSource("stateshover", {
      type: "geojson",
      data: "https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson"
    });
  }
  if (!map.getLayer("state-fills")) {
    map.addLayer({
      id: "state-fills",
      type: "fill",
      source: "stateshover",
      layout: {},
      paint: {
        "fill-color": "rgba(255,0,0,0.5)",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0
        ]
      }
    });
  }




  map.on("mousemove", "state-fills", function(e) {
    map.getCanvas().style.cursor = 'pointer'
    if (e.features.length > 0) {
      if (hoveredStateId !== null) {
        map.setFeatureState(
          { source: "stateshover", id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = e.features[0].id;
      map.setFeatureState(
        { source: "stateshover", id: hoveredStateId },
        { hover: true }
      );
    }
  });

  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  map.on("mouseleave", "state-fills", function() {
    map.getCanvas().style.cursor = ''
    if (hoveredStateId !== null) {
      map.setFeatureState(
        { source: "stateshover", id: hoveredStateId },
        { hover: false }
      );
    }
    hoveredStateId = null;
  });
  map.on('zoom', 'state-fills',() => {
    popup.remove();
  });

  // The feature-state dependent fill-opacity expression will render the hover effect
  // when a feature's hover state is set to true.
}

function drawmap(markerClusterGroup, travelers, map, userRole, popup) {
  let newData = [];
  let i = 0;
  let dummy = {};
  let radius = 6;
  // let popup = new mapboxgl.Popup({
  //   closeButton: true,
  //   closeOnClick: false
  // });
  // use travelers instead dummydatatravellers
  travelers.map(item => {
    if (item.geoLocation && item.geoLocation.latitude) {
      newData.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.geoLocation.longitude, item.geoLocation.latitude]
        },
        properties: {
          traveler: item,
          currentJourneyStatus: item.currentJourneyStatus
        }
      });
      i++;
    }
  });

  let data1 = {};
  if (newData.length >= 1) {
    data1 = {
      type: "FeatureCollection",
      features: [...newData]
    };
  }
  if (!map.getSource("travellerslist")) {
    map.addSource("travellerslist", {
      type: "geojson",
      data: data1,
      cluster: true,
      clusterMaxZoom: 20, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });
  }

  if (!map.getLayer("clusters")) {
    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "travellerslist",
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-color": "#002D72",
        "circle-radius": 10,
        "circle-stroke-width": 5,
        "circle-stroke-color": "#B3BECC",
        "circle-radius": 10,
        "circle-radius-transition": {
          duration: 0
        }
      }
    });
  }
  // now add the layer, and reference the data source above by name
  if (!map.getLayer("cluster-count")) {
    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "travellerslist",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12
      },
      paint: {
        "text-color": "#fff"
      }
    });
  }
  var colorExpressionPoint = [
    "case",
    ["==", ["get", "currentJourneyStatus"], "alarm"],
    "#f25050",
    ["==", ["get", "currentJourneyStatus"], "warning"],
    "#ffb700",
    ["==", ["get", "currentJourneyStatus"], "ok"],
    "#49bf78",
    "#49bf78"
  ];

  var strokeColorExpressionPoint = [
    "case",
    ["==", ["get", "currentJourneyStatus"], "alarm"],
    "#dfbfbf",
    ["==", ["get", "currentJourneyStatus"], "warning"],
    "#e2d4b0",
    ["==", ["get", "currentJourneyStatus"], "ok"],
    "#bed5c7",
    "#bed5c7"
  ];

  if (!map.getLayer("unclustered-point")) {
    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "travellerslist",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": colorExpressionPoint,
        "circle-radius": 10,
        "circle-stroke-width": 5,
        "circle-stroke-color": strokeColorExpressionPoint,
      }
    });

  }

  if (!map.getLayer("unclustered-count")) {
    map.addLayer({
      id: "unclustered-count",
      type: "symbol",
      source: "travellerslist",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "text-field": "1",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12
      },
      paint: {
        "text-color": "#fff"
      }
    });
  }
  // inspect a cluster on click
  map
    .on("click", "clusters", function(e) {
      if(popup) popup.remove();
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      var clusterId = features[0].properties.cluster_id;
      popup.remove();
      if (map.getZoom() < SPIDERIFY_AFTER_ZOOM) {
        map
          .getSource("travellerslist")
          .getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) {
              return;
            }

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
      } else {
        spiderifiedCluster = {
          id: clusterId,
          coordinates: features[0].geometry.coordinates
        };
        spiderifyCluster({
          map: map,
          source: SOURCE_EARTHQUAKE,
          clusterToSpiderify: spiderifiedCluster,
          userRole: userRole,
          popup: popup
        });
      }
    })
    .on("click", e => {
      clearSpiderifiedCluster(map);
    })
    .on("zoomstart", () => {
      clearSpiderifiedCluster(map);
    });
  map.on("mouseenter", "cluster-count", function(e) {
    // map.getCanvas().style.cursor = 'pointer'
    var features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
    var clusterId = features[0].properties.cluster_id,
      point_count = features[0].properties.point_count,
      clusterSource = map.getSource(
        /* cluster layer data source id */ "travellerslist"
      );

    // Get Next level cluster Children
    //
    let dataonclusterpoint = [];
    // clusterSource.getClusterChildren(clusterId, function(err, aFeatures) {
    //   console.log("getClusterChildren", err, aFeatures);
    // });

    // Get all points under a cluster
    clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
      err,
      aFeatures
    ) {
      dataonclusterpoint = aFeatures;

      var coordinates = e.features[0].geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let okstatus = aFeatures.filter(
        el => el.properties.currentJourneyStatus === "ok"
      );
      let alarmstatus = aFeatures.filter(
        el => el.properties.currentJourneyStatus === "alarm"
      );
      let warningstatus = aFeatures.filter(
        el => el.properties.currentJourneyStatus === "warning"
      );

      popup
        .setLngLat(coordinates)
        .setHTML(
          `<div style="padding-top: 9px">
            ${
            alarmstatus.length > 0
              ? `<img style="height:13px;width:13px;margin-right:5px;z-index:9999;float:left;" src=${Alarm} /> <span style='display:block;margin-top:-1px;float:left;'>${alarmstatus.length} ${alarmstatus.length === 1?'Traveler':'Travellers'}</span><br/>`
              : ""
            }
            ${
            warningstatus.length > 0
              ? `<img
                  style="height:13px;width:13px;margin-right:5px;z-index:9999;float:left;"
                  src=${Warning}
                 /> <span style='display:block;margin-top:-1px;float:left;'>${warningstatus.length} ${warningstatus.length === 1?'Traveler':'Travelers'}</span><br/>`
              : ""
            }
${
            okstatus.length > 0
              ? `<img style="height:13px;width:13px;margin-right:5px;z-index:9999;float:left;" src=${OK} /> <span style='display:block;margin-top:-1px;float:left;'>${okstatus.length} ${okstatus.length === 1?'Traveler':'Travelers'}</span><br/>`
              : ""
            }


        </div>`
        )
        .addTo(map);
    });
    //%%%%%%%%%%%%
  });
  map.on("mousemove", "cluster-count", function() {
    map.getCanvas().style.cursor = 'pointer'
  });

  map.on('zoom', 'cluster-count',() => {
    popup.remove();
  });

  map.on("mouseleave", "cluster-count", function() {
    map.getCanvas().style.cursor = ''
  });
  // When a click event occurs on a feature in
  // the unclustered-point layer, open a popup at
  // the location of the feature, with
  // description HTML from its properties.
  map.on("mouseenter", "unclustered-point", function(e) {

    var coordinates = e.features[0].geometry.coordinates.slice();

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    let traveler = JSON.parse(e.features[0].properties.traveler);
    let popupHtml = getTravelerLocationPopupContent(traveler, userRole);
    popup
      .setLngLat(coordinates)
      .setHTML(popupHtml)
      .addTo(map);
  });

  map.on("mousemove", "unclustered-point", function() {
    map.getCanvas().style.cursor = 'pointer'
  });

  map.on('zoom', 'unclustered-point',() => {
    popup.remove();
  });

  map.on("mouseleave", "unclustered-point", function() {
    map.getCanvas().style.cursor = ''
  });

  // map.on("mouseenter", "clusters", function() {
  //   map.getCanvas().style.cursor = 'pointer'
  // });
  map.on("mousemove", "clusters", function() {
    map.getCanvas().style.cursor = 'pointer'
  });
  map.on("mouseleave", "clusters", function() {
    map.getCanvas().style.cursor = "";
  });
}

export const getTravelerLocationPopupContent = (traveler, userRole) => {
  const isVip = traveler.isVIP ? '<span class="vip">VIP</span>' : '';
  const productDate = traveler.geoLocation.date.$date;
  const productTz = traveler.geoLocation.timeZoneName;
  const productType = traveler.geoLocation.event_type;
  const productName = traveler.geoLocation.name;
  const productFormattedDate = getTimezoneTime(productDate, 'MMM D, hh:mm A', productTz);
  const costCenterName = traveler.costCenter && traveler.costCenter.name ? traveler.costCenter.name : '';
  const orgName = isCompanyAdminOrUser(userRole) && costCenterName ?  costCenterName : traveler.organization.name;
  const popupHtml = `
    <div class="popup-heading">
        <img
                style='height:30px;width:30px;z-index:9999;margin-right: 10px;'
                src=${
    traveler.currentJourneyStatus == "ok"
      ? OK
      : traveler.currentJourneyStatus == "alarm"
      ? Alarm
      : Warning
    } />
    
      <div class="holder fdcol">
        <div class="name-area">
          <strong class="travelers-name">${traveler.firstName} ${traveler.lastName}</strong>
          ${isVip}
        </div>
        <span class="travelers-organization">${orgName}</span>
      </div>
    </div>
    <div class="area">
      <span class="muted">${productFormattedDate}</span>
      <span>${productType} - ${productName}</span>
    </div>
  `;

  return popupHtml;
}

export const drawLocationsHelper = (
  markerClusterGroup,
  travelers,
  map,
  userRole,
  popup
) => {
  drawmap(markerClusterGroup, travelers, map, userRole, popup);
  return markerClusterGroup;
};

export const drawCountriesBorders = (countriesJSON, countries, map) => {
  if (!countries.length) {
    return null;
  }

  // TODO: mapbox-gl GEOJSON
  // const layer = L.geoJson(countriesJSON, {
  //   style: function (feature) {
  //     const currentCountry = countries.find(
  //       country => country.longCode === feature.id || country.country === feature.properties.name
  //     );
  //     const level = currentCountry ? currentCountry.level : null;
  //     switch (level) {
  //       case 1:
  //         return { color: '#ff0000', opacity: 0, fillColor: '#376dc0', fillOpacity: 0.5 };
  //       case 2:
  //         return { color: '#ff0000', opacity: 0, fillColor: '#edd21e', fillOpacity: 0.5 };
  //       case 3:
  //         return { color: '#ff0000', opacity: 0, fillColor: '#dd8107', fillOpacity: 0.5 };
  //       case 4:
  //         return { color: '#ff0000', opacity: 0, fillColor: '#d14830', fillOpacity: 0.5 };
  //       default:
  //         return { color: '#ff0000', opacity: 0, fillColor: '#fff', fillOpacity: 0 };
  //     }
  //   }
  // }).addTo(map);
  // return layer;
};

export const drawTravelAdvisoryHelper = (
  countries,
  map,
  clickCb,
  countriesJSON,
  countryCentersJSON
) => {
  if (!countries.length) {
    return null;
  }

  let colorExpressionPoints = [
    "case",
    ["==", ["get", "level"], 1],
    "rgba(21, 70, 144,0.7)",
    ["==", ["get", "level"], 2],
    "rgba(237, 210, 29,0.7)",
    ["==", ["get", "level"], 3],
    "rgba(220, 173, 116,0.7)",
    ["==", ["get", "level"], 4],
    "rgba(213, 144, 133,0.7)",
    "#fff"
  ];
  let countriesJSONCopy = JSON.parse(JSON.stringify(countriesJSON));
  let masterCountry = [];
  let travelerFeatures = [];

  countriesJSONCopy.features.map(country => {
    let countryFormat = country;

    countries.map(innercountry => {
      if (innercountry.longCode === country.id) {
        let properties = countryFormat.properties;
        properties = { ...properties, ...innercountry };
        countryFormat.properties = properties;
        masterCountry.push(countryFormat);
        if (innercountry.travelers && innercountry.travelers.length) {
          let countryCenterFeature = countryCentersJSON.features.find( feature => feature.id === innercountry.longCode);

          if (countryCenterFeature) {
            let newFeature = {
              "type": "Feature",
              "id": countryCenterFeature.id,
              "geometry": {
                "type": "Point",
                "coordinates": countryCenterFeature.geometry.coordinates
              },
              "properties" : {
                totalTravelers: innercountry.travelers.length.toString(),
                countryCode: innercountry.longCode
              }
            }
            travelerFeatures.push(newFeature);
          }
        }
      }
    });

  });

  countriesJSONCopy.features = masterCountry;
  countriesJSONCopy.features.push(...travelerFeatures);
  let breakNow = false;

  ["states-layer-circle", "states-layer-text", "states-layer"].forEach( (layerName) => {
    if (map.getLayer(layerName)) {
      map.removeLayer(layerName);
    }
  });
  if ( map.getSource("states") ) {
    map.removeSource("states");
  }
  map.addSource("states", {
    type: "geojson",
    data: countriesJSONCopy
  });
  map.addLayer({
    id: "states-layer",
    type: "fill",
    source: "states",
    paint: {
      "fill-color": colorExpressionPoints,
      "fill-outline-color": "rgba(200, 100, 240, 1)"
    },
    'filter': ['==', '$type', 'Polygon']
  });

  map.addLayer({
    id: "states-layer-circle",
    type: "circle",
    source: "states",
    'paint': {
      'circle-radius': 18,
      'circle-color': '#FFFFFF'
    },
    'filter': ['==', '$type', 'Point']
  });
  map.addLayer({
    id: "states-layer-text",
    type: "symbol",
    source: "states",
    layout: {
      "text-field": ["get", "totalTravelers"],
      "text-anchor": "center",

    },
    'filter': ['==', '$type', 'Point']
  });
  map.on('mouseenter', 'states-layer-circle', () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'states-layer-circle', () => {
    map.getCanvas().style.cursor = ''
  })

  map.on("click", "states-layer-circle", function(e) {
    let countryCode = e.features[0].properties.countryCode;
    clickCb(countryCode);
  });

};


const updateRoutesAndAirportsList = (flightsStatuses, routesList, airportsList,filteredTravelers) => {
  flightsStatuses.forEach( (departureAirport) => {
    if (!departureAirport.geoLocation) {
      return;
    }
    // for (var j = 0; j < filteredTravelers.length; j++) {
    //   if (!departureAirport.subscribersIds.includes(filteredTravelers[j]._id.$oid)) {
    //     return;
    //   }
    // }
    let sourceCoordinates = departureAirport.geoLocation.coordinates;

    departureAirport.destinations.forEach( (destination) => {
      if (destination.flights && (destination.flights.length > 0) && destination.geoLocation) {
        let destinationCoordinates = destination.geoLocation.coordinates;

        // Add the Airports to the airportsList which is used to
        // show each of the airports in the map
        let sourceAirportPoint = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: sourceCoordinates
          },
          properties: { code: departureAirport.code }
        }
        airportsList.push(sourceAirportPoint);

        let destinationAirportPoint = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: destinationCoordinates
          },
          properties: { code: destination.code }
        }
        airportsList.push(destinationAirportPoint)

        // get all the subscriberIds who are on the flights between these two airports
        let ids = [];
        destination.flights.forEach( (flight) => {
          flight.subscribers.forEach( (subscriber) => {
            ids.push(subscriber._id.$oid);
          });
        });

        // The routes are used for plotting lines between the two airports
        let route = {
          type: "Feature",
          properties: {
            departureAirport: departureAirport,
            destination: destination,
            ids: ids.join(','),
            routeCode: departureAirport.code + '-' + destination.code,
            lineColor: "#002D72",
            lineWidth: 1
          },
          geometry: {
            type: "LineString",
            coordinates: [sourceCoordinates, destinationCoordinates]
          }
        };
        // Draw an arc using turf.greatCircle and replace route's geometry with the new arc's geometry
        let arc = turf.greatCircle(sourceCoordinates, destinationCoordinates, {npoints: 500});
        route.geometry = arc.geometry;
        routesList.push(route);
      }
    });

  });
}

const addRoutesAndAirportsListToMap= (routesList, airportsList, map) => {
  //console.log('Inside addRoutesAndAirportsListToMap');
  if (!map.getSource("flight-path")) {
    map.addSource("flight-path", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: airportsList
      }
    });
  }

  map.loadImage(FlightsArcIcon, function(error, image) {
    if (error) throw error;
    if (!map.hasImage("flightsDots")) {
      map.addImage("flightsDots", image);
    }

    if (!map.getLayer("flight-path")) {
      map.addLayer({
        id: "flight-path",
        source: "flight-path",
        type: "symbol",
        layout: {
          "text-field": ["get", "code"],
          "text-variable-anchor": ["top", "bottom", "left", "right"],
          "text-radial-offset": 0.5,
          "text-justify": "auto",
          "icon-image": "flightsDots",
          "icon-size": 0.075
        },
      });
    }
  });

  if (!map.getSource("multiple-lines-source")) {
    map.addSource("multiple-lines-source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: routesList
      }
    });
  }
  if (!map.getLayer("multiple-lines-layer")) {
    map.addLayer({
      id: "multiple-lines-layer",
      type: "line",
      source: "multiple-lines-source",
      layout: {},
      paint: {
        "line-color": ["get", "lineColor"],
        "line-width": ["get", "lineWidth"]
      }
    });
  }

  // This layer is to make the popup appear more easily.
  // We are essentially creating a thick layer with almost zero opacity
  if (!map.getLayer("multiple-lines-invisible-layer")) {
    map.addLayer({
      id: "multiple-lines-invisible-layer",
      type: "line",
      source: "multiple-lines-source",
      layout: {},
      paint: {
        "line-color": ["get", "lineColor"],
        "line-width": 4,
        "line-opacity" : 0.01
      }
    });
  }
}


const removeReverseRoutes = (routesList,filteredTravelers) => {
  let newRouteList = []
  routesList.forEach( (route) => {
      let reverseRouteCode = route.properties.destination.code + '-' + route.properties.departureAirport.code;
      let reverseRoute = newRouteList.find( (route) => route.properties.routeCode === reverseRouteCode);
      if (reverseRoute) {
        console.log(route.properties.routeCode, 'Found reverse route');
        reverseRoute.properties.reverseDeparture = route.properties.departureAirport;
        reverseRoute.properties.reverseDestination = route.properties.destination;
        reverseRoute.properties.reverseIds = route.properties.ids;
      } else {

        newRouteList.push(route);
      };
  });
  return newRouteList;
}

const addEmissionsPopupContentForRoutes = (routesList, filterFlightStatusTimeframe) => {
  routesList.forEach( (route) => {
    let popupContent = getFlightPopupContentForEmission(
      route.properties.departureAirport,
      route.properties.destination,
      filterFlightStatusTimeframe
    );
    route.properties.popUpContent = popupContent;
  });
}

export const drawFlightStatusesArcsForEmission = (
  flightsStatuses,
  filteredTravelers,
  filterFlightStatusTimeframe,
  map,
  popup
) => {

  let routesList = [];
  let airportsList = []
  let filteredRouterList = [];
  let filteredAirportList = [];
  let airportlistcodes = [];
  let unique = [];


  updateRoutesAndAirportsList(flightsStatuses, routesList, airportsList);
  routesList = removeReverseRoutes(routesList);

  for (var i = 0; i < routesList.length; i++){
    for (var j = 0; j < filteredTravelers.length; j++){
      if(routesList[i].properties.ids.includes(filteredTravelers[j]._id.$oid)){
        filteredRouterList.push(routesList[i])
      }
    }

  }
  for (var i = 0; i < filteredRouterList.length; i++){
    for (var j = 0; j < airportsList.length; j++){
      if(filteredRouterList[i].properties.routeCode.includes(airportsList[j].properties.code)){
        airportlistcodes.push(airportsList[j].properties.code)
      }
    }

  }
  unique = airportlistcodes.filter((v, i, a) => a.indexOf(v) === i);

  console.log(unique);
  console.log(airportsList);
  for (var j = 0; j < airportsList.length; j++) {
    if (unique.includes(airportsList[j].properties.code)) {
      filteredAirportList.push(airportsList[j])
    }
  }

  console.log('Add back popup content for emission routes');
  addEmissionsPopupContentForRoutes(filteredRouterList, filterFlightStatusTimeframe);
  addRoutesAndAirportsListToMap(filteredRouterList, filteredAirportList, map);


  map.on("mouseenter", "multiple-lines-invisible-layer", function(e) {
    map.getCanvas().style.cursor = 'pointer'
    popup.remove();
    popup
      .setLngLat(e.lngLat)
      .setHTML(e.features[0].properties.popUpContent)
      .addTo(map);
  });

  map.on("mouseleave", "multiple-lines-layer", function() {
    map.getCanvas().style.cursor = "";
  });



  return {
    arcs: [],
    airportMarkers: []
  };
};

const updateColorCodesForRoutes = (routesList) => {
  routesList.forEach( (route) => {
    route.properties.lineColor = config.map.warning;
    let inboundFlights = route.properties.destination.flights;
    let outboundFlights = route.properties.reverseDestination ? route.properties.reverseDestination.flights : [];
    let allFlights = [
      ...inboundFlights,
      ...outboundFlights
    ];
    let tempFlight = allFlights.find( (flight) => flight.flightState === 'alarm' );
    if (tempFlight) {
      route.properties.lineColor = config.map.alarm;
      return;
    }
    tempFlight = allFlights.find( (flight) => flight.flightState === 'warning' );
    if (tempFlight) {
      route.properties.lineColor = config.map.warning;
      return;
    }
    route.properties.lineColor = config.map.ok;
  });
}

export const addFlightsPopupContentForRoutes= (routesList, filteredTravelers, userRole) => {
  routesList.forEach( (route) => {
    let depCode = route.properties.departureAirport.code;
    let desCode = route.properties.destination.code;
    let inboundFlights = route.properties.destination.flights;
    let outboundFlights = route.properties.reverseDestination ? route.properties.reverseDestination.flights : [];
    let allFlights = [
      ...inboundFlights,
      ...outboundFlights
    ];
    let popUpContent = '';
    if (allFlights.length === 1) {
      popUpContent = getFlightPopupContent(allFlights[0], filteredTravelers, userRole);
    } else {
      let flightsList = inboundFlights.map((flight) => getFlightListItem(flight)).join('');
      let rFlightsList = outboundFlights.length >= 1 ? outboundFlights.map((flight) => getFlightListItem(flight)).join('') : false;
      flightsList = flightsList ? `<h3 style="font-weight: 400;font-size: 20px;">${depCode}-${desCode}</h3><ul>${flightsList}</ul>` : '';
      rFlightsList = rFlightsList ? `<h3 style="font-weight: 400;font-size: 20px; padding-top: 2rem ">${desCode}-${depCode}</h3><ul>${rFlightsList}</ul>` : '';
      let flightsContent = allFlights.map((flight) => {
        return getFlightPopupContent(flight, filteredTravelers, userRole);
      }).join('');
      popUpContent = `
        <div id="popup-flights">
          <div class="tabset" style="padding-bottom: 0px;
    vertical-align: top;">
            ${flightsList}
            ${rFlightsList}
          </div>
          <div class="tabs-holder">${flightsContent}</div>
        </div>
      `;
    }
    route.properties.popUpContent = popUpContent;
  });

}

export const drawFlightStatusesArcsHelper = (
  flightsStatuses,
  filteredTravelers,
  map,
  userRole,
  popup
) => {
  console.log('Inside drawFlightStatusesArcsHelper');
  let routesList = [];
  let airportsList = []
  let filteredRouterList = [];
  let filteredAirportList = [];
  let airportlistcodes = [];
  let unique = [];
  console.log(flightsStatuses);
  updateRoutesAndAirportsList(flightsStatuses, routesList, airportsList,filteredTravelers);
  routesList = removeReverseRoutes(routesList);
  console.log(routesList);


  // console.log(filteredTravelers);
  // console.log(airportsList);
  for (var i = 0; i < routesList.length; i++){
    for (var j = 0; j < filteredTravelers.length; j++){
      if(routesList[i].properties.ids.includes(filteredTravelers[j]._id.$oid)){
        filteredRouterList.push(routesList[i])
      }
    }

  }
  console.log(airportsList);
  for (var i = 0; i < filteredRouterList.length; i++){
    for (var j = 0; j < airportsList.length; j++){
      if(filteredRouterList[i].properties.routeCode.includes(airportsList[j].properties.code)){
        airportlistcodes.push(airportsList[j].properties.code)
      }
    }

  }
  unique = airportlistcodes.filter((v, i, a) => a.indexOf(v) === i);

  console.log(unique);
  console.log(airportsList);
  for (var j = 0; j < airportsList.length; j++) {
    if (unique.includes(airportsList[j].properties.code)) {
      filteredAirportList.push(airportsList[j])
    }
  }


  console.log(filteredRouterList);
  console.log('Adding popup content for routes');
  addFlightsPopupContentForRoutes(filteredRouterList, filteredTravelers, userRole);
  updateColorCodesForRoutes(filteredRouterList);
  addRoutesAndAirportsListToMap(filteredRouterList, filteredAirportList, map);

  map.on("mouseenter", "multiple-lines-invisible-layer", function(e) {
    map.getCanvas().style.cursor = 'pointer'
    popup.remove();
    popup
      .setLngLat(e.lngLat)
      .setHTML(e.features[0].properties.popUpContent)
      .addTo(map);
    initTabs();
  });

  map.on('mouseleave', 'multiple-lines-invisible-layer', () => {
    map.getCanvas().style.cursor = ''
  });
  // map.on("mouseleave", "multiple-lines-layer", function() {
  //   popup.remove();
  // });
  //
  // map.on("mouseleave", "multiple-lines-layer", function() {
  //   map.getCanvas().style.cursor = "";
  //   popup.remove();
  // });
  map.on('zoom', 'multiple-lines-invisible-layer',() => {
    popup.remove();
  });

  return {
    arcs: [],
    airportMarkers: []
  };
}

const drawAirports = (airportsCoords, map) => {
  // console.log("INSIDE DRAWAIRPORTS");
  const airportMarkers = [];
  airportsCoords.forEach(airportCoord => {
    if (!airportCoord.flights || !airportCoord.flights.length) {
      return;
    }
    // console.log("INSIDE DRAWAIRPORTS afterv return");
    const coords = airportCoord.coords;
    // let markerIcon = L.divIcon({
    //   className: 'airport',
    //   ids: flatten(airportCoords.flights.map(flight => flight.subscribersIds)),
    //   iconSize: [10, 10]
    // });

    // let marker = L.marker([coords[1], coords[0]], {
    //   icon: markerIcon
    // }).addTo(map);

    const el = document.createElement("div");
    el.className = "airport";
    el.style.width = "10px";
    el.style.height = "10px";
    el.ids = flatten(airportCoord.flights.map(flight => flight.subscribersIds));
    // console.log("before marking marker in DRAWAIRPORTS", coords[1], coords[0]);
    if (
      coords[1] > -90 &&
      coords[1] < 90 &&
      coords[0] > -90 &&
      coords[0] < 90
    ) {
      const marker = new mapboxgl.Marker(el)
        .setLngLat([coords[1], coords[0]])
        .addTo(map);
      // console.log("end of DRAWAIRPORTS");
      airportMarkers.push(marker);
    }
  });

  return airportMarkers;
};

export const getFlightPopupContentForEmission = (flightInfo, destination, filterFlightStatusTimeframe) => {

  let carbon = destination.carbon;
  if (!carbon) {
    return `
    <div class="tab" id="${flightInfo.code}-${destination.code}">
      <div class='head'>
          <div>
            <strong style='margin-bottom:5px;font-weight:bold;'>No carbon data available</strong>
          </div>
      </div>
    </div>
    `
  }
  let distance = carbon.distance;
  let emission = carbon.emission;

  let total_travellers = destination.flights.reduce( function(acc, flight) {
    if (flight.subscribers) {
      return acc + flight.subscribers.length;
    }
  }, 0);
  // console.log(total_travellers, "total travellers");
  let travelersText = '1 Traveler';
  if (total_travellers === 0) {
    travelersText = 'No Traveler';
  } else if ( total_travellers > 1) {
    travelersText = total_travellers + ' Travelers';
  }
  return `
    <div class="tab" id="${flightInfo.code}-${destination.code}">

      <div class='head'>

          <div>
            <strong style='margin-bottom:5px;font-weight:bold;color: #101427;padding-bottom: 1rem;border-bottom: 1px solid #DAE1ED;'>
            ${flightInfo.code} - ${destination.code}</strong>
          </div>
      </div>
      <div class='dep-des' style='width:100%;float:left;'>
        <div class='holder' style='width:100%;'>
              <strong style='margin-bottom: 5px;font-weight: normal;font-size: 1rem; color: #595B60; line-height: 20px;'>Distance (mi): ${distance} </strong>
        </div>
        <div class='holder' style='width:100%;'>
          <strong style='margin-bottom: 5px;font-weight: normal;font-size: 1rem;color: #595B60;line-height: 20px;'>Emissions(lbs): ${emission*total_travellers} </strong>
        </div>
        <div class='holder' style='width:100%;'>
          <strong style='height: 14px;width: 230px;color: #101427;font-family: "Open Sans"; font-size: 16px; letter-spacing: 0; line-height: 14px; padding-top: 1rem; padding-bottom: 1rem; font-weight: normal;'>
            <img style="height:16px;width:16px;margin-right: -1px;margin-bottom: 6px;" src=${Traveler} />  ${travelersText} within ${filterFlightStatusTimeframe} hours.</strong>
        </div>
        </div>
      </div>
    </div>
  `
}

export const getFlightPopupContent = (flight, filteredTravelers, userRole) => {
  const timeFormat = 'MMM D|hh:mm A';
  if (!(flight.arrivalLocation && flight.departureLocation)) {
    console.error(flight.number + i18nTools.l(' - Flight status - incorrect flight information'));
    return false;
  }
  const departureTZ = flight.departureLocation.timeZoneName;
  const arrivalTZ = flight.arrivalLocation.timeZoneName;
  const departureTime = flight.departureLocation.scheduledDateTime.$date;
  const arrivalTime = flight.arrivalLocation.scheduledDateTime.$date;
  const eDepartureTime = flight.departureLocation.estimatedDateTime.$date;
  const eArrivalTime = flight.arrivalLocation.estimatedDateTime.$date;
  const scheduledDepartureTime = i18nTools.getTimezoneTime(departureTime, timeFormat, departureTZ).split('|');
  const scheduledArrivalTime = i18nTools.getTimezoneTime(arrivalTime, timeFormat, arrivalTZ).split('|');

  let estimatedDepartureTime = false;
  let estimatedArrivalTime = false;
  if (departureTime !== eDepartureTime) {
    estimatedDepartureTime = i18nTools.getTimezoneTime(eDepartureTime, timeFormat, departureTZ).split('|');
    if (scheduledDepartureTime[0] === estimatedDepartureTime[0]) {
      scheduledDepartureTime[0] = '';
    }
  }
  if (arrivalTime !== eArrivalTime) {
    estimatedArrivalTime = i18nTools.getTimezoneTime(eArrivalTime, timeFormat, arrivalTZ).split('|');
    if (scheduledArrivalTime[0] === estimatedArrivalTime[0]) {
      scheduledArrivalTime[0] = '';
    }
  }

  const travelers = uniq(flight.subscribers.map((traveler) => {
    const organization = traveler.organization ? traveler.organization.name : '';
    const costCenterName = traveler.costCenter && traveler.costCenter.name ? traveler.costCenter.name : '';
    const orgName = isCompanyAdminOrUser(userRole) && costCenterName ? costCenterName : organization;

    const isVip = indexOf(traveler.rank, 'VIP') !== -1 ? '<span class="vip" style="margin-top: 2px;padding: 3px;margin-bottom: 5px;height: 15px;">VIP</span>' : '';
    if (findIndex(filteredTravelers, ['_id.$oid', traveler._id.$oid]) === -1) return '';
    console.log(filteredTravelers);
    return `
      <li>${traveler.firstName} ${traveler.lastName}
       &middot; <span class='comp-name' style="padding-left: 2px"> ${orgName}</span> ${isVip}</li>
    `;
  })).join('');
  let status = flight.status;
  if (flight.delay) {
    status = `
      ${i18nTools.humanizeDuration(flight.delay, 'm', 'flight-delay').replace('-', '')}
      ${flight.delay > 0 ? i18nTools.l('delay') : i18nTools.l('early')}
    `;
  }
  console.log(flight);
  return `
    <div class="tab" id="${flight.number}" style="width: 21rem;padding-left: 4px">
    <div style="display: flex">
       <img
                style='height:30px;width:30px;z-index:9999;'
                src=${
    flight.flightState == "ok"
      ? OK
      : flight.flightState == "alarm"
      ? Alarm
      : Warning
    } />
      <div class='head' style="padding-left: 1rem">
        <h4 style="font-size: 20px;font-weight: normal">${flight.carrier.code} ${flight.number}  ${fly(flight)}</h4>
        <strong class="${flight.delay >= 0 ? flight.flightState : 'ok'}" style="font-size: 14px">${status}</strong>
      </div>
      </div>
      <div class='dep-des' style="border-top: 1px solid #f1f2f4; margin-left: -4px; margin-right: -5px">
        <div class='holder' style="font-size: 16px;">
          <div class='arrow_box'>
              <span>${flight.departureLocation.code}</span><br />
              <span>
                ${estimatedDepartureTime ? estimatedDepartureTime.join('<br/>') : scheduledDepartureTime.join('<br/>')}
              </span>
              <del>${estimatedDepartureTime ? scheduledDepartureTime.join('<br/>') : ''}</del>
          </div>
          <div>
              <span>${flight.arrivalLocation.code}</span><br />
              <span>
                ${estimatedArrivalTime ? estimatedArrivalTime.join('<br/>') : scheduledArrivalTime.join('<br/>')}
              </span>
              <del>${estimatedArrivalTime ? scheduledArrivalTime.join('<br/>') : ''}</del>
          </div>
        </div>
        <ul class='popup-travelers-list' style="font-size: 16px; border-top:1px solid #f1f2f4;">
          ${travelers}
        </ul>
      </div>
    </div>
  `;
};

export const getFlightListItem = flight => {
  return `
    <li class="flight-item" data-id="${flight.number}" style="font-size: 16px">
      <span>${flight.carrier.code} ${flight.number} ${fly(flight)}</span>
      <strong class="${flight.flightState}" style="padding-left: 10px">${flight.status}</strong>
    </li>
  `;
};

const fly = flight => {
  const flightStatus = flight.status.toLowerCase();
  if (flightStatus !== "canceled") {
    const nowUTC = moment()
      .utc()
      .valueOf();
    const eDepartureTime = flight.departureLocation.estimatedDateTime.$date;
    const eArrivalTime = flight.arrivalLocation.estimatedDateTime.$date;
    return nowUTC > eDepartureTime && nowUTC < eArrivalTime
      ? '<em class="flyss"></em>'
      : "";
  }
  return "";
};

// TODO: mapbox-gl POPUP/POLYLINE
const initTabs = () => {
  const $popupFlights = $('#popup-flights');
  if ($popupFlights.length) {
    const $tabs = $popupFlights.find('div.tab');
    let minHeight = 0;
    $tabs.each((i, tab) => {
      const tabHeight = $(tab).height();
      if (minHeight < tabHeight) {
        minHeight = tabHeight;
      }
    });
    $popupFlights.find('div.flight-item:first').addClass('active');
    $tabs.css('minHeight', minHeight).hide().eq(0).show();
  }
};

export const popupTabsInit = () => {
  $(document.body).off("mouseenter.tabs");
  $(document.body).on("mouseenter.tabs", "li.flight-item", function(e) {
    const $popupFlights = $("#popup-flights");
    $popupFlights.find("div.flight-item").removeClass("active");
    $popupFlights.find("div.tab").hide();
    // $(this).addClass("active");
    $popupFlights.find("#" + $(this).data("id")).show();
  });
};

export const clearMap1 = (map, popup) => {

  popup.remove();

  // removes locations layer
  if (map.getLayer("flight-path")) {
    map.removeLayer("flight-path");
  }
  if (map.getSource("flight-path")) {
    map.removeSource("flight-path");
  }

  // removes locations layer
  if (map.getLayer("multiple-lines-layer")) {
    map.removeLayer("multiple-lines-layer");
  }
  if (map.getLayer("multiple-lines-invisible-layer")) {
    map.removeLayer("multiple-lines-invisible-layer");
  }
  if (map.getSource("multiple-lines-source")) {
    map.removeSource("multiple-lines-source");
  }
};

export const clearMap = (
  map,
  markerClusterGroup,
  airportMarkers,
  flightsArcs,
  doNotClosePopup,
  from
) => {
  if (airportMarkers !== null) {
    for (var i = airportMarkers.length - 1; i >= 0; i--) {
      airportMarkers[i].remove();
    }
    airportMarkers = [];
  }
  let allLayers = [
    'flight-path',
    'multiple-lines-layer',
    'multiple-lines-invisible-layer',
    'clusters',
    'cluster-count',
    'unclustered-point',
    'unclustered-count',
    'state-fills'
  ]
  allLayers.forEach( (layer) => {
    if (map.getLayer(layer)) {
      map.removeLayer(layer);
    }
  });

  let allSources = [
    'flight-path',
    'multiple-lines-source',
    'stateshover',
    'travellerslist'
  ]

  allSources.forEach( (source) => {
    if (map.getSource(source)) {
      map.removeSource(source);
    }
  });
  markerClusterGroup, airportMarkers, flightsArcs = []
  return {
    markerClusterGroup,
    airportMarkers,
    flightsArcs,
  };
};
