/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../components/common/select/Select';
import filter from 'lodash/filter';
import $ from 'jquery';
import { sprintf } from '../../i18n/utils';
import DatePicker from 'react-datepicker';
// import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { isCompanyAdminOrUser } from '../../helpers/user';
import DropdownMenu from 'react-dd-menu';
import { NavLink } from 'react-router-dom';
import 'react-dd-menu/dist/react-dd-menu.css';
// import Main from './datePicker';
// import Filter from '../../styles/images/filters-layers.svg';
import Close from '../../styles/images/Close.svg';
import Bluefilter from '../../styles/images/filters-layers-blue.svg';
import DateInput from '../../../src/components/DayPicker/DayPicker';
import x from '../../styles/images/Status-Red.svg';
import warning from '../../styles/images/Status-Yellow.svg';
import ok from '../../styles/images/Status-Green.svg';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import { format as formatDate, startOfMonth,endOfMonth, eachDayOfInterval,isSameMonth,isSameDay,isToday, lastDayOfMonth,addMonths,getDay,addDays,setDate } from 'date-fns'

import Data from '../../../dummydata'

function getCurrentDate() {
  return new Date();
}


export class TravelersFilter extends React.Component {



  static contextTypes = {
    i18n: PropTypes.object
  };

  static propTypes = {
    travelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    filteredTravelers: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    companyList: PropTypes.array.isRequired,
    userRole: PropTypes.string.isRequired,
    costCenterList: PropTypes.array,
    carrierList: PropTypes.array,
    airportList: PropTypes.array,
    flightStatusList: PropTypes.array,
    setTravelersFilter: PropTypes.func.isRequired,
    travelersFilter: PropTypes.object.isRequired,
    clearTravelersFilter: PropTypes.func.isRequired,
    hideStatusFilter: PropTypes.any,
    menuOptions: PropTypes.object.isRequired,
    title: PropTypes.any,
  };

  constructor (props) {
    super(props);
    this.state = {
      alarm: false,
      currentDate: getCurrentDate(),
      isSelected:false
    };
    this._warningRef = React.createRef();
    this._alarmRef = React.createRef();
    this._okRef = React.createRef();
    this._vipRef = React.createRef();
    this._dateRangeTypeOptions = ['Custom'];
    this._dateRangeType = this.props.travelersFilter.dateRangeType;
    this._dateRangeStart = this.props.travelersFilter.dateRangeStart;
    this._dateRangeEnd = this.props.travelersFilter.dateRangeEnd;
    // this._nonHelpedRef = React.createRef();
    this._selectDefault = [];
    this._company = this.props.travelersFilter.company;
    this._health = this.props.travelersFilter.health;
    this._security = this.props.travelersFilter.security;
    this._pointOfInterest = this.props.travelersFilter.pointOfInterest;
    this._carrier = this.props.travelersFilter.carrier;
    this._airport = this.props.travelersFilter.airport;
    this._fstatus = this.props.travelersFilter.fstatus;
    this._costcenter_id = this.props.travelersFilter.costcenter_id;
    this._continents = this.props.travelersFilter.continents;

    let additionalFilterArr = [];
    if (this.props.travelersFilter.vip) additionalFilterArr.push('vip');
    this._additionalFilter = additionalFilterArr.join(',')
    let status = this.props.travelersFilter.status;
    this._currentStatus = '';
    // this.setFilter = this.setFilter.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  clear = (e) => {
    e.preventDefault();
    this.props.clearTravelersFilter();
    this._company = this._selectDefault;
    this._carrier = this._selectDefault;
    this._airport = this._selectDefault;
    this._fstatus = this._selectDefault;
    this._costcenter_id = this._selectDefault;
    this._health = this._selectDefault;
    this._pointOfInterest = this._selectDefault;
    this._security = this._selectDefault;
    this._continents = this._selectDefault;
    this._additionalFilter = this._selectDefault;
    this._currentStatus = '';
    this.setState({isSelected:false})
  }

  UNSAFE_componentWillMount() {
    this.props.clearTravelersFilter();
    this._company = this._selectDefault;
    this._carrier = this._selectDefault;
    this._airport = this._selectDefault;
    this._fstatus = this._selectDefault;
    this._costcenter_id = this._selectDefault;
    this._health = this._selectDefault;
    this._pointOfInterest = this._selectDefault;
    this._security = this._selectDefault;
    this._continents = this._selectDefault;
    this._additionalFilter = this._selectDefault;
    this._currentStatus = '';
  }


  // handleButtonClick = () => {
  //   this.setState(state => {
  //     return {
  //       open: !state.open
  //     };
  //   });
  // };

  // handleSubmit (event) {
  //   event.preventDefault();
  // }

  getFormattedDate = () => {
    return formatDate(this.state.currentDate, 'MMMM/DD/YYYY');
  };

  changeCurrentDate = (name,date, e) => {
   return (date) => {
     this['_' + name] = date.date;
     if (this._dateRangeStart && this._dateRangeEnd) {
       setTimeout(this.setFilter);
     }
   }
  };



  dateRangeChange = (e) => {
    return (selected) => {
      this._dateRangeType = selected.value;
      switch (selected.value) {
        // case 'Current':
        //   this._dateRangeStart = moment().local().startOf('day');
        //   this._dateRangeEnd = moment().local().endOf('day');
        //   break;
        // case 'Last 7 Days':
        //   this._dateRangeStart = moment().local().subtract(7, 'day').startOf('day');
        //   this._dateRangeEnd = moment().local().endOf('day');
        //   break;
        // case 'Last 30 Days':
        //   this._dateRangeStart = moment().local().subtract(30, 'day').startOf('day');
        //   this._dateRangeEnd = moment().local().endOf('day');
        //   break;
        case 'Custom':
          this._dateRangeStart = moment().local().subtract(1, 'day').startOf('day').toDate();
          this._dateRangeEnd = moment().local().endOf('day').toDate();
          break;
      }

      setTimeout(this.setFilter);
    };
  }

  selectChange = (name) => {
    return (selected) => {
      if (Object.prototype.hasOwnProperty.call(selected, 'value')) {
        this['_' + name] = selected.value.split(',');
      } else if (selected) {
        this['_' + name] = selected.split(',');
      } else {
        this['_' + name] = [];
      }
      setTimeout(this.setFilter);
    };
  }

  statusChange = (name) => {
    return (value) => {
      this['_' + name] = value;
      setTimeout(this.setFilter);
    }
  }

  dateChange = (name) => {
    return (value) => {
      this['_' + name] = value;
      if (this._dateRangeStart && this._dateRangeEnd) {
        setTimeout(this.setFilter);
      }
    };
  }

  setFilter = (e) => {
    const filters = { ...this.props.travelersFilter };
    // if (this._vipRef !== undefined && this._vipRef.current !== undefined) {
    //   if (!Object.prototype.hasOwnProperty.call(this.props)) {
    //     filters.status = {
    //       alarm: this._alarmRef.current.checked,
    //       warning: this._warningRef.current.checked,
    //       ok: this._okRef.current.checked
    //     };
    //   }

    //   // filters.vip = this._vipRef.current.checked;
    //   // filters.nonHelped = this._nonHelpedRef.current.checked;
    // }

    filters.dateRangeType = this._dateRangeType;
    let status = filters.status;
    Object.keys(status).forEach( (key) => status[key] = false);

    if (this._currentStatus.length) {
      let statuses = this._currentStatus.split(',');
      statuses.forEach( (status) => filters.status[status] = true);
    }
    if (filters.dateRangeType !== 'Current' && this._dateRangeStart) {
      if (this._dateRangeStart instanceof Date) {
        filters.dateRangeStart = this._dateRangeStart.toISOString().split('T')[0];
      } else {
        filters.dateRangeStart = this._dateRangeStart.format('YYYY-MM-DD');
      }
    } else {
      filters.dateRangeStart = null;
    }
    if (filters.dateRangeType !== 'Current' && this._dateRangeEnd) {
      if (this._dateRangeEnd instanceof Date) {
        filters.dateRangeEnd = this._dateRangeEnd.toISOString().split('T')[0];
      } else {
        filters.dateRangeEnd = this._dateRangeEnd.format('YYYY-MM-DD');
      }
    } else {
      filters.dateRangeEnd = null;
    }
    if (filters.company) {
      filters.company = this._company;
    }
    if (filters.carrier) {
      filters.carrier = this._carrier;
    }
    if (filters.airport) {
      filters.airport = this._airport;
    }
    if (filters.fstatus) {
      filters.fstatus = this._fstatus;
    }
    if (filters.costcenter_id) {
      filters.costcenter_id = this._costcenter_id;
    }
    if (filters.health) {
      filters.health = this._health;
    }
    if (filters.security) {
      filters.security = this._security
    }
    if (filters.pointOfInterest){
      filters.pointOfInterest = this._pointOfInterest;
    }
    if (filters.continents) {
      filters.continents = this._continents;
    }
    // This is for additional filters like 'vip'

    filters.vip = this._additionalFilter.includes('vip');

    this.props.setTravelersFilter(filters);
    this.setState({isSelected:true})
    if(filters.company?.length==0 && filters.continents?.length==0
      && filters.status.alarm===true && filters.status.warning===true &&
      filters.status.ok===true && filters.vip===false ){
      this.setState({isSelected:false})
    }

  };
  // setFilter = (e) => {
  //   const filters = { ...this.props.travelersFilter };
  //   if (!Object.prototype.hasOwnProperty.call(this.props, 'hideStatusFilter')) {
  //     filters.status = {
  //       alarm: this._alarmRef.current.checked,
  //       warning: this._warningRef.current.checked,
  //       ok: this._okRef.current.checked
  //     };
  //   }
  //   filters.vip = this._vipRef.current ? this._vipRef.current.checked : null;
  //   // filters.nonHelped = this._nonHelpedRef.current ? this._nonHelpedRef.current.checked : null;
  //   if (filters.company) {
  //     filters.company = this._company;
  //   }
  //   if (filters.carrier) {
  //     filters.carrier = this._carrier;
  //   }
  //   if (filters.airport) {
  //     filters.airport = this._airport;
  //   }
  //   if (filters.fstatus) {
  //     filters.fstatus = this._fstatus;
  //   }
  //   if (filters.costcenter_id) {
  //     filters.costcenter_id = this._costcenter_id;
  //   }
  //   console.log(filters, 'anything');
  //   this.props.setTravelersFilter(filters);
  // };

  render () {

    const dateinpStyle = {

      height: '27px',

      width: '92px',
      borderRadius:'6px',
      color: '#595B60',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      padding:'10px',
      border:"1px solid lightgray"

    };

    const { l, ngettext } = this.context.i18n;
    const {
      travelers,filteredTravelers,
      companyList, costCenterList,
      carrierList, airportList, flightStatusList, travelersFilter, userRole,
      hideStatusFilter, menuOptions,title
    } = this.props;
    const { dateRangeType, company, carrier, airport, fstatus, status, health, security, pointOfInterest, continents, vip, country, state } = travelersFilter;
    // this._currentStatus = Object.keys(status).filter( (key) => status[key]).join(',');
    let additionalFilter = vip ? 'vip' : '';
    let currentContinents = continents.join(',');

    // const { vip, nonHelped, company, carrier, airport, fstatus, status } = travelersFilter;
    const costcenterId = travelersFilter.costcenter_id.join(',');
    const dateRangeTypeOptions = this._dateRangeTypeOptions.map((i) => { return { value: i, label: l(i) }; });

    let costcenterSelect = null;
    if (isCompanyAdminOrUser(userRole) && costCenterList && costCenterList.length) {
      // if (!find(costCenterList, { 'value': 'all' })) {
      //   costCenterList.unshift({value: 'all', label: l('All Sort level ID')});
      // }

      costcenterSelect = (
        <Select
          value={costcenterId}
          options={costCenterList}
          searchable
          multi
          simpleValue
          clearable = {false}
          className='company-select'
          placeholder={l('All Sort level ID')}
          // optionRenderer={({ label }) => (<label>{label}</label>)}
          onChange={this.selectChange('costcenter_id')} />
      );
    }

    let companySelect = null;
    if (!isCompanyAdminOrUser(userRole) && companyList && company) {
      // if (!find(companyList, { 'value': 'all' })) {
      //   companyList.unshift({value: 'all', label: l('All Companies')});
      // }

      companySelect = (
        <Select
          value={this._company}
          options={companyList}
          // searchable
          multi
          simpleValue
          clearable = {false}
          // clearable
          className='company-select'
          // optionRenderer={({ label }) => (<label>{label}</label>)}
          placeholder={l('All Companies')}
          arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
          )}
          onChange={this.selectChange('company')} />
      );
    }

    let countriesSelect = (
      <Select
        disabled={true}
        value={country}
        // options={Data.countries}
        // searchable
        multi
        simpleValue
        clearable = {false}
        // clearable
        className='country-select'
        // optionRenderer={({ label }) => (<label>{label}</label>)}
        placeholder={l('All Countries')}
        arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
        )}
        onChange={this.selectChange('country')} />
    );

    let stateSelect = (
      <Select
        disabled={true}
        value={state}
        // options={Data.countries}
        // searchable
        multi
        simpleValue
        // clearable
        clearable = {false}
        className='state-select'
        // optionRenderer={({ label }) => (<label>{label}</label>)}
        placeholder={l('All States')}
        arrowRenderer = {() => (<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
        )}
        onChange={this.selectChange('state')} />
    );

    let carrierSelect = null;
    if (carrierList && carrier) {
      const carrierSortedList = sortBy(carrierList, 'label');
      // if (!find(carrierSortedList, { 'value': 'all' })) {
      //   carrierSortedList.unshift({value: 'all', label: l('All Carriers')});
      // }

      carrierSelect = (
        <Select
          value={carrier}
          options={carrierSortedList}
          searchable
          multi
          simpleValue
          clearable = {false}
          placeholder={l('All Carriers')}
          optionRenderer={({ label }) => (<label>{label}</label>)}
          onChange={this.selectChange('carrier')} />
      );
    }
    let airportSelect = null;
    if (airportList && airport) {
      const airportSortedList = sortBy(airportList, 'label');
      // if (!find(airportSortedList, { 'value': 'all' })) {
      //   airportSortedList.unshift({value: 'all', label: l('All Airports')});
      // }

      airportSelect = (
        <Select
          clearable = {false}
          value={airport}
          options={airportSortedList}
          searchable
          multi
          simpleValue
          placeholder={l('All Airports')}
          optionRenderer={({ label }) => (<label>{label}</label>)}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('airport')} />
      );
    }

    let flightStatusSelect = null;
    if (flightStatusList && fstatus) {
      flightStatusSelect = (
        <Select
          clearable = {false}
          value={fstatus}
          options={flightStatusList}
          multi
          simpleValue
          placeholder={l('All Flight Statuses')}
          optionRenderer={({ label }) => (<label>{label}</label>)}
          arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
          onChange={this.selectChange('fstatus')} />
      );
    }
    // let {travelersFilter} = this.props.travelersFilte
    let info;
    if (travelers && filteredTravelers) {
      let trLength = filteredTravelers;
      let totalLength = travelers;
      if (typeof travelers === 'object') {
        trLength = filter(filteredTravelers, { status: 'active' }).length;
      }
      if (typeof travelers === 'object') {
        if (!$('#map-flights').hasClass('active')) {
          totalLength = filter(travelers, { status: 'active' }).length;
        } else {
          // totalLength = sessionStorage.get('TravelerCount');
          // totalLength = filter(travelers, function (traveler) {
          //  return (traveler.status === 'active' &&
          //    (traveler.currentJourneyStatus === 'warning' || traveler.currentJourneyStatus === 'alarm'));
          // }).length;
        }
      }
      if (title === 'flight') {
        const text = sprintf(ngettext(
          `%d flight of ${totalLength}`,
          `%d flights of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      } else {
        const text = sprintf(ngettext(
          `%d traveler of ${totalLength}`,
          `%d travelers of ${totalLength}`,
          trLength
        ), trLength);
        info = totalLength ? text : null;
      }
    }

    const continentStatus =[
      {
        value: 'namerica',
        label: 'North America'
      },
      {
        value: 'samerica',
        label: 'South America'
      },
      {
        value: 'europe',
        label: 'Europe'
      },
      {
        value: 'asia',
        label: 'Asia'
      },
      {
        value: 'oceania',
        label: 'Oceania'
      },
      {
        value: 'africa',
        label: 'Africa'
      },
    ]
    let continentSelect= null;
    continentSelect = (
      <Select
      clearable = {true}
      value={this._continents}
      options={continentStatus}
      searchable
      multi
      simpleValue
      className="continent-select active-continent"
      placeholder={l('All Continents')}
      arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
      // optionRenderer={({ label }) => (<label><div className="continent-label">{label}</div></label>)}
      onChange={this.selectChange('continents')}
      />
    )

    const statusCheckboxes = !hideStatusFilter;
    const flightStatus = [
      {
        classValue: 'alarm',
        label: 'Red',
        value: 'alarm'
      },
      {
        classValue: 'warning',
        label: 'Yellow',
        value: 'warning'
      },
      {
        classValue: 'ok',
        label: 'Green',
        value: 'ok'
      }
    ]
    let disruptionStatus = null;
    if (status) {
      disruptionStatus = (<Select
        clearable = {true}
        value={this._currentStatus}
        options={flightStatus}
        searchable
        multi
        simpleValue
        className='disruption-select'
        onSelectAll = {"All Disruption"}
        placeholder={l('All Disruption')}
        arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
        optionRenderer={({ label, classValue }) => {
          if(classValue === 'ok'){
            return(<label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {ok}/>
          <div className="Dlabel">Green</div></div></label>)
          }else
           if(classValue === 'warning'){
           return( <label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {warning}/>
          <div className="Dlabel">Yellow</div></div></label>)
          }else{
           return (<label><div className="Dicon">
              <img className='disruption-icon ico-align' src= {x}/>
          <div className="Dlabel">Red</div></div></label>)
          }
        }}
        onChange={this.statusChange('currentStatus')} />)
    }

    //   ? (
    // <div className='travelers-filter__dropdown'>
    //   {this.state.open && (
    //   <ul className='travelers-filter__dropdown-status-selector'>
    //     <li>
    //       <input id='st01' checked={status && status.alarm}
    //         type='checkbox' ref={this._alarmRef} onChange={(e) => {
    //           this.setFilter(e);
    //         }} />
    //       <label htmlFor='st01'><i className='icon alarm'>{l('alarm')}</i> Red</label>
    //     </li>
    //     <li>
    //       <input id='st02' checked={status && status.warning}
    //         type='checkbox' ref={this._warningRef} onChange={(e) => { this.setFilter(e); }} />
    //       <label htmlFor='st02'><i className='icon warning'>{l('warning')}</i> Yellow</label>
    //     </li>
    //     <li>
    //       <input id='st03' checked={status && status.ok}
    //         type='checkbox' ref={this._okRef} onChange={(e) => { this.setFilter(e); }}/>
    //       <label htmlFor='st03'><i className='icon ok'>{l('ok')}</i> Green</label>
    //     </li>
    //   </ul>
    //   )}
    // </div>)
    //   : null;

    const AdditionalStatus =[
      {
        value: 'vip',
        label: 'VIPs Only'
      },

    ]
    let Additional= null;
    Additional = (
      <Select
        clearable = {false}
      value={this._additionalFilter}
      options={AdditionalStatus}
      searchable
      multi
      simpleValue
      className="disruption-select cx"
      placeholder={l('Select')}
      arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
      onChange={this.selectChange('additionalFilter')}
      />
    )

    const HealthStatus =[
      {
        value: 'CDC Alerts',
        label: 'CDC Alerts',
        disabled: true
      },
      {
        value: 'Clinics & Hospitals',
        label: 'Clinics & Hospitals',
        disabled: true
      },
    ]
    let healths= null;
    healths = (
      <Select
      disabled = {true}
      clearable = {false}
      value={health}
      options={HealthStatus}
      searchable
      multi
      simpleValue
      className="continent-select"
      placeholder={l('Select')}
      arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg"></i> )}
      // optionRenderer={({ label }) => (<label><div className="continent-label">{label}</div></label>)}
      onChange={this.selectChange('health')}
      />
    )

    const SecurityStatus =[
      {
        value: 'Travel Advisories',
        label: 'Travel Advisories'
      },
    ]
    let Security= null;
    Security = (
      <Select
      clearable = {false}
      value={security}
      options={SecurityStatus}
      searchable
      multi
      simpleValue
      className="continent-select"
      placeholder={l('Select')}
      arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
      // optionRenderer={({ label }) => (<label><div className="continent-label">{label}</div></label>)}
      onChange={this.selectChange('security')}
      />
    )

    const PoiStatus =[
      {
        value: 'Office Locations',
        label: 'Office Locations',
        disabled: true
      },
      {
        value: 'Preferred Hotels',
        label: 'Preferred Hotels',
        disabled: true
      },
      {
        value: 'Public Transportation',
        label: 'Public Transportation',
        disabled: true
      },
      {
        value: 'Weather',
        label: 'Weather',
        disabled: true
      },
    ]
    let PointOfInterest= null;
    PointOfInterest = (
      <Select
      disabled={true}
      clearable = {false}
      value={pointOfInterest}
      options={PoiStatus}
      searchable
      multi
      simpleValue
      className="continent-select"
      placeholder={l('Select')}
      isOptionDisabled={option => option.isDisabled == true}
      arrowRenderer = {() => (<i class="fa fa-angle-down fa-lg" aria-hidden="true"></i> )}
      // optionRenderer={({ label }) => (<label><div className="continent-label">{label}</div></label>)}
      onChange={this.selectChange('pointOfInterest')}

      />
    )

    return (
      <>
      <DropdownMenu {...menuOptions} className='travelers-filter__dropdown'>
        <header className='travelers-filter__dropdown-header'>
          <div className='travelers-filter__dropdown-title-cnt'>
            <h3 className='travelers-filter__dropdown-title'><img src={Bluefilter} className='filt' alt='filter'/>Filters & Layers</h3>
            {/* <NavLink to='/settings/global-filter' activeClassName='active'>
              <i className='icon-global-settings' />
            </NavLink> */}
          </div>
          <button className='travelers-filter__close-btn' onClick={menuOptions.close}>
            <img src={Close} alt='' style={{height : "24px"}}/>
          </button>
        </header>
        <p className='travelers-filter__dropdown-titl'>{l('Filters')}</p>
        {/*<p style={{ fontSize: '16px', fontWeight: 'bold', color: '#007680' }} className='travelers-filter__dropdown-option-title'>Date Range</p>*/}
        {/*<DateInput*/}
          {/*formattedDate={this.getFormattedDate()}*/}
          {/*currDate={this._dateRangeStart}*/}
          {/*changeCurrentDate={this.changeCurrentDate}*/}
          {/*name="dateRangeStart"*/}
          {/*id = "dateRangeStart"*/}
          {/*inpStyles={dateinpStyle}*/}
          {/*dateformat='MM/DD/YYYY'*/}
          {/*spanStyle={{padding:'10px 10px 10px 5px'}}*/}
          {/*alignCalender="dayPickerLeft"*/}

        {/*/>*/}
        {/*<label className='to-label'>to</label>*/}
        {/*<DateInput*/}
          {/*formattedDate={this.getFormattedDate()}*/}
          {/*currDate={this._dateRangeEnd}*/}
          {/*changeCurrentDate={this.changeCurrentDate}*/}
          {/*name="dateRangeEnd"*/}
          {/*id = "dateRangeEnd"*/}
          {/*inpStyles={dateinpStyle}*/}
          {/*dateformat='MM/DD/YYYY'*/}
          {/*spanStyle={{padding:'10px'}}*/}
          {/*alignCalender="dayPickerLeft"*/}
        {/*/>*/}


        {/*<div className='filter-dateranges-container'>*/}
            {/*<div className='date-range'>*/}
              {/*<div className='filter-dateranges'>*/}

               {/*<DatePicker id='dateRangeStart' name='dateRangeStart' autoComplete='off'*/}
                 {/*className='filter-daterange'*/}
                  {/*selected={this._dateRangeStart} maxDate={this._dateRangeEnd}*/}
                  {/*onChange={this.dateChange('dateRangeStart')} />*/}
                {/*<label className='to-label'>to</label>*/}
                {/*<DatePicker id='dateRangeEnd' name='dateRangeEnd' autoComplete='off'*/}
                {/*className='filter-daterange'*/}
                {/*selected={this._dateRangeEnd} minDate={this._dateRangeStart}*/}
                {/*onChange={this.dateChange('dateRangeEnd')} />*/}
              {/*</div>*/}
            {/*</div>*/}
        {/*</div>*/}
        <hr/>
        {/* <div className='travelers-filter__dropdown-body'> */}
          <div className='disruption-status'>
          <p>Disruption Status</p>
            <div className='active-trvlr'>{ disruptionStatus }</div>
          {/* </div> */}
          <hr />
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#007680' }} className='travelers-filter__dropdown-option-title'>Location</p>

          <div style={{ width: '200px' }} className='travelers-filter__dropdown-option-holder'>
            {flightStatusSelect}
            <div className='company' style={{ display:"flex" }}>

              <span>{costcenterSelect}{companySelect}</span><span className='disabled-op'> {countriesSelect}</span>

            </div>

            {carrierSelect}
            {airportSelect}
          </div>
          <div className='continent' style={{ display:"flex" }}>
            <span>{continentSelect}</span> <span className='disabled-op'>{stateSelect}</span>
          </div>
          {/* <select  className='Contnient' value={this.state.contnient} onChange={this.handleChange}>
            <option name="N.America">N.America</option>
            <option name="S.America">S.America</option>
            <option name="Europe">Europe</option>
            <option name="Asia">Asia</option>
            <option name="Oceania">Oceania</option>
            <option name="Africa">Africa</option>
          </select> */}
            {/* <div >
            {flightStatusSelect}
            {companySelect}
            {costcenterSelect}
            {carrierSelect}
            {airportSelect}
          </div> */}
          <hr/>
           <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#007680' }} className='travelers-filter__dropdown-option-title'>Additional Filters </p>

          <div style={{ width: '200px' }} className='travelers-filter__dropdown-option-holder'>
            {flightStatusSelect}
            <div className='disruption-status'>
            {Additional}
            </div>
            {/* {costcenterSelect}
            {carrierSelect}
            {airportSelect} */}
          </div>
          <hr/>
          <p className='travelers-filter__dropdown-titl'>{l('Layers')}</p>
            <div>
              {/*<div className={"grayout"}> Coming Soon</div>*/}
          <p className='Health'>Health </p>
          <div className='healths'>
            <span className='disabled-op'>{healths}</span>
            </div>
          {/* <hr/> */}
          {/*<p className='Security'>Security: </p>*/}
          {/*<div className='security'>*/}
            {/*{Security}*/}
            {/*</div>*/}
          {/* <hr/> */}
          <p className='poi'>Points of Interest </p>
          <div className='interest'>
            <span className='disabled-op'>{PointOfInterest }</span>
            </div>
            </div>

          {/* <ul className='travelers-filter__dropdown-status-selector'>
            <li>
              <input id='need' checked={nonHelped} type='checkbox'
                ref={this._nonHelpedRef} name='nonHelped' onChange={this.setFilter} />
              <label htmlFor='need'>{l('Needs Assistance')}</label>
            </li>
            <li>
              <input id='vip' checked={vip} type='checkbox'
                ref={this._vipRef} name='vip' onChange={this.setFilter} />
              <label htmlFor='vip'>{l('VIP Only')}</label>
            </li>
          </ul> */}
        </div>

        <footer className='travelers-filter__dropdown-footer'>
        <button className={this.state.isSelected?'select-button':'clear-button'}><a className='clear-all' href='/' onClick={this.clear} >{l('Clear All')}</a></button>
        </footer>
      </DropdownMenu>
      </>
    );
  }
}
export default TravelersFilter;
