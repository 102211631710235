/* eslint-disable max-len */
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function NotifIcon (props) {
  return (
    <SvgIcon {...props}>
        <rect id="Rectangle" x="0" y="2.52631579" width="13.4736842" height="11.7894737" rx="2"></rect>
        <circle id="Oval" stroke="#002D72" cx="11.7894737" cy="4.21052632" r="3.71052632"></circle>
    </SvgIcon>
  );
}
