import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import TravelersFilter from '../common/travelersFilter';
import FlightsTable from './flightsTable';
import EmissionsTable from './emissionsTable';
import Pager from '../common/pager';
import LoadingIcon from '../../components/common/loadingIcon';
import StickyHolder from '../../components/common/stickyHolder';
import { getFlightStatusList } from '../../helpers/flightStatusFilter';
import config from '../../config/index';
import {  toast } from 'react-toastify';
import i18nTools from '../../helpers/i18nTools';
// import AppMenuItem from '../common/AppMenu';
// import Main from '../activeTravelers/datePicker';

// const headerHeight = config.layout.headerHeight;

export class FlightsStatusList extends React.Component {
  static propTypes = {
    travelersFlights: PropTypes.array.isRequired,
    travelersFlightsFilter: PropTypes.object.isRequired,
    travelersFlightsLoading: PropTypes.bool.isRequired,
    travelersFlightsFilterEmbedded: PropTypes.object.isRequired,
    travelersFlightsMeta: PropTypes.object.isRequired,
    travelersFlightsSortBy: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    travelersFlightsSortByDirect: PropTypes.bool.isRequired,
    appSettingsDimensions: PropTypes.object.isRequired,
    setTravelersFlightsSorter: PropTypes.func.isRequired,
    loadTravelersFlights: PropTypes.func.isRequired,
    setTravelersFlightsFilter: PropTypes.func.isRequired,
    clearTravelersFlightsFilter: PropTypes.func.isRequired,
    travelersFlightsPage: PropTypes.number.isRequired,
    switchTravelersFlightsPage: PropTypes.func.isRequired,
    getEntireTravelersFlights: PropTypes.func.isRequired,
    isPinned: PropTypes.bool.isRequired,
    setExportFlightDetailsFlag: PropTypes.func.isRequired
  };

  static contextTypes = {
    i18n: PropTypes.object
  };

  changeView = () => {
    let newView = 'flights';
    if (this.state.view === 'flights') {
      newView = 'carbon';
    }
    this.setState( (state, props) => {
      return {view: newView}
    });
  }

  constructor(props) {
    super(props);
    this._flightsTableRef = React.createRef();
    this.state = {
      isFilterShadow: false,
      isDownloadShadow: false,
      dShadow:'',
      fShadow:'',
      view : 'flights'
    }
  }

  exportToFile = (fileType) => {
    if (this.props.travelersFlightsMeta.isExportInProgress) {
      toast.success(i18nTools.l('Export is in progress.'));
      return;
    }
    if (this._flightsTableRef && this._flightsTableRef.current) {
      this._flightsTableRef.current.exportToFile(fileType);
    }
  }
  toggleFilterShadow = ()=>{
    this.setState({isFilterShadow:!this.state.isFilterShadow})
    {!this.state.isFilterShadow?this.setState({fShadow:'0 2px 20px 0 #007680'}):this.setState({fShadow:'none'})}
  }
  toggleDownloadShadow = ()=>{
   this.setState({isDownloadShadow:!this.state.isDownloadShadow})
    {!this.state.isDownloadShadow?this.setState({dShadow:'0 2px 20px 0 #007680'}):this.setState({dShadow:'none'})}
  }
  handleShadow = ()=>{
      if(this.state.isFilterShadow){
        this.setState({isFilterShadow:!this.state.isFilterShadow})
        this.setState({fShadow:'none'})
      }
      if(this.state.isDownloadShadow){
        this.setState({isDownloadShadow:!this.state.isDownloadShadow})
        this.setState({dShadow:'none'})
      }
  }
  render() {
    const { l } = this.context.i18n;
    const {
      travelersFlights,
      travelersFlightsFilterEmbedded,
      setTravelersFlightsSorter,
      travelersFlightsSortBy,
      travelersFlightsSortByDirect,
      travelersFlightsFilter,
      setTravelersFlightsFilter,
      clearTravelersFlightsFilter,
      travelersFlightsPage,
      travelersFlightsMeta,
      switchTravelersFlightsPage,
      travelersFlightsLoading,
      getEntireTravelersFlights,
      userRole
    } = this.props;

    let flightsTableComponent;
    if (travelersFlights && travelersFlights.length) {
      if (this.state.view === 'flights') {
        flightsTableComponent = (
          <FlightsTable
            ref={this._flightsTableRef}
            getEntireTravelersFlights={getEntireTravelersFlights}
            travelersFlights={travelersFlights}
            sortBy={travelersFlightsSortBy}
            sortByDirect={travelersFlightsSortByDirect}
            flightsSorter={setTravelersFlightsSorter}
            userRole={userRole} {...this.props} />
        );
      } else {
          flightsTableComponent = (
            <EmissionsTable
              ref={this._flightsTableRef}
              getEntireTravelersFlights={getEntireTravelersFlights}
              travelersFlights={travelersFlights}
              sortBy={travelersFlightsSortBy}
              sortByDirect={travelersFlightsSortByDirect}
              flightsSorter={setTravelersFlightsSorter}
              userRole={userRole} {...this.props} />
          );
      }
    } else {
      flightsTableComponent = <span style={{marginLeft:'18px'}}>{l('No flights found')}</span>
    }

    return (
      <div className='clearfix scrollable-horizontally page-content' onClick={this.handleShadow}>
        <LoadingIcon loading={travelersFlightsLoading} />
        <div className='head-row'>
          <div className='head'>
            {/* <h1>{l('Flight Status')}</h1> */}
            <StickyHolder>
              <TravelersFilter
                dShadow={this.state.dShadow}
                fShadow={this.state.fShadow}
                isFilterShadow= {this.state.isFilterShadow}
                isDownloadShadow= {this.state.isDownloadShadow}
                toggleFilterShadow= {this.toggleFilterShadow}
                toggleDownloadShadow= {this.toggleDownloadShadow}
                travelers={travelersFlightsMeta.totalFlights}
                filteredTravelers={travelersFlightsMeta.filteredFlights}
                perPage={travelersFlightsMeta.pageIndex}
                isExportInProgress={travelersFlightsMeta.isExportInProgress}
                companyList={travelersFlightsFilterEmbedded.companies}
                airportList={travelersFlightsFilterEmbedded.airports}
                carrierList={travelersFlightsFilterEmbedded.carriers}
                costCenterList={travelersFlightsFilterEmbedded.costCenters}
                title='flight'
                flightStatusList={getFlightStatusList()}
                travelersFilter={travelersFlightsFilter}
                setTravelersFilter={setTravelersFlightsFilter}
                hideStatusFilter
                clearTravelersFilter={clearTravelersFlightsFilter}
                userRole={userRole}
                changeView={this.changeView}
                view={this.state.view}
                userSettings={this.props.userSettings}
                exportToFile={this.exportToFile} />
            </StickyHolder>
          </div>
          {flightsTableComponent}
          <div>
            <Pager
              page={travelersFlightsPage}
              pages={travelersFlightsMeta.totalPages}
              switchPage={switchTravelersFlightsPage} />
          </div>
        </div>
      </div>
    );
  }
}

export default FlightsStatusList;
